import * as _propTypes3 from "prop-types";
var _propTypes2 = _propTypes3;
try {
  if ("default" in _propTypes3) _propTypes2 = _propTypes3.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ariaLabelsShape = void 0;
var _propTypes = _interopRequireDefault(_propTypes2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
const ariaLabelsShape = exports.ariaLabelsShape = _propTypes.default.shape({
  dateInput: _propTypes.default.objectOf(_propTypes.default.shape({
    startDate: _propTypes.default.string,
    endDate: _propTypes.default.string
  })),
  monthPicker: _propTypes.default.string,
  yearPicker: _propTypes.default.string,
  prevButton: _propTypes.default.string,
  nextButton: _propTypes.default.string
});
export default exports;